<template lang="pug">
  .row
    .col-md-6
      .component-card
        .row
          .col-md-6
            v-image-select(v-model="img")
          .col-md-6
            v-image-select(
              square
              v-model="img")
    .col-md-6
      v-card
        v-card-title
          h2.card-title Card title
        v-card-content
          | Lorem ipsum dolor sit amet
        v-card-actions
          v-btn(text) Cancel
          v-btn Ok
</template>

<script>
import VImageSelect from '@/components/ui/v-image-select/VImageSelect'

export default {
  name: 'VImageSelectDemo',

  components: {
    VImageSelect
  },

  data: () => ({
    img: ''
  })
}
</script>

<style>
</style>
